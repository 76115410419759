/* eslint-disable no-undef */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Analytics from 'react-router-ga';

import { Login } from '@spidertracks/common';

import { PublicRoute } from './PublicRoute';
import { MainLayoutPrivateRoute } from './MainLayoutPrivateRoute';
import { MainLayoutPublicRoute } from './MainLayoutPublicRoute';
import { PrivateRoute } from './PrivateRoute';

import onSuccessfulLogin from './onSuccessfulLogin';

import {
  ORGANISATION,
  HISTORY,
  INSIGHTS_ARCHIVED,
  INSIGHTS_SSO_LOGIN,
  FLIGHT,
  INSIGHTS,
  EVENTS_EXPLORER,
  SPIDERTXT
} from '../common/constants';

import LoginLayout from '../layout/Login';

import NgContainer from '../packages/ng/containers/NgContainer';

import { FourOhFour } from '../pages/404';
import PublicFlyingPage from '../pages/public-flying';
import SharedTracks from '../pages/public-flying/SharedTracks';
import OrganisationRoot from '../pages/organisation/OrganisationRoot';
import HistoryRoot from '../pages/history/HistoryRoot';
import { InsightsRedirect } from '../pages/insights/InsightsRedirect';
import FlyingPage from '../pages/flying';
import FlyingPageV2 from '../pages/flying-v2';
import SpiderTxtPage from '../pages/spidertxt';
import FlightPage from '../pages/flying/FlightPage';

import InsightsArchivedRoot from '../components/InsightsArchived/InsightsArchived';
import InsightsRoot from '../components/Insights/Insights';
import EventsExplorer from '../components/EventsExplorer';

const Routes = () => (
  <Analytics id={window.env.STL_GOOGLE_ANALYTICS_TRACKING}>
    <Switch>
      <Route
        path="/login"
        render={({ history, location }) => (
          <LoginLayout>
            <PublicRoute
              path="/"
              component={Login}
              authCallback={onSuccessfulLogin(history, location)}
            />
          </LoginLayout>
        )}
      />
      <MainLayoutPublicRoute path="/public/e/:token" component={SharedTracks} />
      <MainLayoutPublicRoute path="/public/:organisationSlug" component={PublicFlyingPage} />
      <PrivateRoute path={INSIGHTS_SSO_LOGIN} component={InsightsRedirect} />
      <MainLayoutPrivateRoute path="/">
        <Route exact path="/" component={FlyingPage} />
        {(window.env.ENV === 'local' || window.env.ENV === 'development') && (
          <Route exact path="/flying-v2" component={FlyingPageV2} />
        )}
        <Route path={HISTORY} component={HistoryRoot} />
        <Route path={INSIGHTS_ARCHIVED} component={InsightsArchivedRoot} />
        <Route path={ORGANISATION} component={OrganisationRoot} />
        <Route path={INSIGHTS} component={InsightsRoot} />
        <Route path={SPIDERTXT} component={SpiderTxtPage} />
        <Route exact path="/safety-insights">
          <Redirect to="/insights" />
        </Route>
        <Route path={EVENTS_EXPLORER} component={EventsExplorer} />
        <Route exact path={`${FLIGHT}/:trackId`} component={FlightPage} />
        <Route exact path={`${FLIGHT}/:serialNumber/:bootcount`} component={FlightPage} />
        {/* Redirects old history page URL to the new history page.  */}
        <Route exact path="/page/history">
          <Redirect to="/history" />
        </Route>
        <Route exact path="/newspidertxt" component={SpiderTxtPage} />
        <Route exact path="/page/newspidertxt">
          <Redirect to="/newspidertxt" />
        </Route>
        <Route exact path="/page/:name" component={NgContainer} />
      </MainLayoutPrivateRoute>
      <Route path="**" component={FourOhFour} />
    </Switch>
  </Analytics>
);

export default Routes;
