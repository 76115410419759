import React, { useCallback, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Spin } from 'antd';
import {
  startMapInitialLoad,
  finishMapInitialLoad
} from '../../redux/reducers/mapReducer/actions/map/mapInitialLoad';
import { mapZoomLevelChange } from '../../redux/reducers/mapReducer/actions/map';
import AircraftTracksContainerV2 from './AircraftTracksContainerV2';
import { GOOGLE_MAP_CONTAINER } from '../Flying/Map/GoogleMap/constants';

const mapDomId = 'SpiderTracksMap2';
window.gmaps = window.gmaps ?? {};
window.gmaps[mapDomId] = {
  cleanMap: () => {
    window.mapData = {
      markers: [],
      polylines: [],
      circles: [],
      infoWindow: [],
      ...window.mapData
    };
    window.mapData.markers.forEach(marker => marker.setMap(null));
    window.mapData.polylines.forEach(polygon => polygon.setMap(null));
    window.mapData.circles.forEach(circle => circle.setMap(null));
    window.mapData.infoWindow.forEach(infoWindow => infoWindow.setMap(null));
    window.mapData = {};
  }
};

// move to googlemapcontainer
const mapOptions = {
  minZoom: 2,
  mapTypeControl: false,
  panControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  preserveViewport: true,
  rotateControl: false,
  center: { lat: 0, lng: 0 },
  zoom: 7,
  scaleControl: true,
  isFractionalZoomEnabled: true
};

const libraries = ['geometry'];

const GoogleMapContainerV2 = ({ className, children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startMapInitialLoad);
  }, []);

  const { isLoaded, loadError: isError } = useLoadScript({
    googleMapsApiKey: window.env.STL_GOOGLE_MAPS_API,
    libraries,
    version: '3.47' // Temporary fix see https://github.com/JustFly1984/react-google-maps-api/issues/2963
  });

  const onLoad = useCallback(function onLoad(mapInstance) {
    window.gmap = mapInstance;
    window.gmaps[mapDomId].gmap = window.gmap;
    dispatch(finishMapInitialLoad);
  }, []);

  const renderMap = () => {
    return (
      <GoogleMap
        id={GOOGLE_MAP_CONTAINER}
        mapContainerClassName={className}
        options={mapOptions}
        onLoad={onLoad}
        onZoomChanged={() => {
          dispatch(mapZoomLevelChange(window.gmap?.getZoom()));
        }}
      >
        {children}
        <AircraftTracksContainerV2 />
      </GoogleMap>
    );
  };

  if (isError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Spin />;
};

GoogleMapContainerV2.propTypes = {
  mapOptions: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};

export default memo(GoogleMapContainerV2);
