import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FavouritesDrawer, FavouritesProvider } from '../../context/FavouritesContext';
import { fetchAircraft } from '../../redux/slice/aircraft';
import { fetchLatestTracks } from '../../redux/slice/tracks';
import { getSelectedTrack, isLastPointInTrack } from '../../redux/selectors/aircraftData';
import { getSelectedMarkerPoint } from '../../redux/selectors/mapData';
import { selectLatestPointInSelectedTrack } from '../../redux/reducers/aircraftReducer/thunk';
import { populateUserData } from '../../redux/reducers/navigationReducer/actions/thunk';
import PointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/PointInfoPanelContainer';
import MapControls from '../../components/Flying/Map/MapControls';
import { MapProvider } from '../../context/MapContext';
import MapContainerV2 from '../../components/FlyingV2/MapContainerV2';
import FlyingPageSideBar from '../../components/FlyingV2/FlyingPageSideBar';

export const FlyingPageV2 = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const selectedTrack = useSelector(getSelectedTrack);
  const selectedMarkerPoint = useSelector(getSelectedMarkerPoint);

  const refreshRateMillis = 15000;

  useEffect(() => {
    dispatch(populateUserData());
    dispatch(fetchAircraft());
    dispatch(fetchLatestTracks());

    const getTrackAndAircraftData = async () => {
      await Promise.all([dispatch(fetchAircraft()), dispatch(fetchLatestTracks())]);
    };

    const intervalId = setInterval(getTrackAndAircraftData, refreshRateMillis);

    return () => clearInterval(intervalId);
  }, [dispatch, fetchAircraft, fetchLatestTracks, refreshRateMillis]);

  useEffect(() => {
    // if there is a selected track that is equal to the previous selected track
    // and has the latest point selected, then update the selected marker point
    if (
      selectedTrack &&
      selectedMarkerPoint &&
      ref.current &&
      ref.current.selectedTrack &&
      ref.current.selectedTrack.trackId === selectedTrack.trackId &&
      isLastPointInTrack(ref.current.selectedTrack, selectedMarkerPoint)
    ) {
      dispatch(selectLatestPointInSelectedTrack());
    }
    ref.current = {
      selectedTrack
    };
  }, [selectedTrack]);

  return (
    <div className="page-flying">
      <FavouritesProvider>
        <MapProvider>
          <MapContainerV2>
            <FlyingPageSideBar />
            <PointInfoPanelContainer />
            <MapControls />
          </MapContainerV2>
        </MapProvider>
        <FavouritesDrawer />
      </FavouritesProvider>
    </div>
  );
};

export default FlyingPageV2;
